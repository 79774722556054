<template>
    <div class="change-box">
        <div class="content-title">商户信息</div>
        <el-row>
            <el-col :span="6" class="item-title">商户编号</el-col>
            <el-col :span="12" class="item-value" v-if="!this.isAdd">
                {{statusData.merchantNo}}
            </el-col>
            <el-col :span="12" v-else>
                <el-input v-model="statusData.merchantNo" style="width:300px; margin-right:10px"></el-input>
                <el-button type="primary" @click="getStastus" v-permission="'MER_CHANGE_APPLY_INFO_INSERT'">查询</el-button>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="6" class="item-title">商户名称</el-col>
            <el-col :span="12" class="item-value">{{statusData.merSubName}}</el-col>
        </el-row>
        <el-row>
            <el-col :span="6" class="item-title">商户状态</el-col>
            <el-col :span="12" class="item-value">{{statusData.merStatus}}</el-col>
        </el-row>
        <el-row>
            <el-col :span="6" class="item-title">开通时间</el-col>
            <el-col :span="12" class="item-value">{{statusData.merCreateTime}}</el-col>
        </el-row>
        <el-row>
            <el-col :span="6" class="item-title">最后一笔交易时间</el-col>
            <el-col :span="12" class="item-value">{{statusData.latestTransTime}}</el-col>
        </el-row>
        <br>
        <div class="content-title">变更申请</div>
        <el-row>
            <el-col :span="6" class="item-title">注销原因说明</el-col>
            <el-col :span="12">
                <el-input type="textarea" v-model="statusData.changeRemark"></el-input>
                <h4>同意变更,我公司承诺：</h4>
                <p>1、确认申请信息为真实、完整、准确；</p>
                <p>2、我公司对该商户最后一笔成功交易日起180天内发生的如：调单、退单、异常资金交易、风险交易、风险事件等情形负责并积极配合贵公司相关事项的处理。</p>
                <p>我公司完全承担因违反上述承诺而实施的行为所造成的全部法律后果和责任，包括可能给乐刷科技有限公司造成的任何经济损失、商业信誉损失等。</p>
                <div><el-checkbox v-model="checked">我已阅读并同意商户变更服务协议</el-checkbox></div>
            </el-col>
        </el-row>
        <br>
        <el-row :gutter="10" class="mb8">
            <el-col :span="8" class="item-title"> </el-col>
            <el-col :span="6">
                <el-button type="primary"
                           @click="submit" v-permission="'MER_CHANGE_APPLY_INFO_INSERT'">
                    申请
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { MerchantApi} from '@/api'
export default {
    data(){
        return{
            statusData:{merchantNo:''},
            checked: true,
            isAdd: true,
            applyNo:''
        }
    },
    created(){
        this.isAdd = this.$route.query.isAdd ? true : false
        this.applyNo = this.$route.query.applyNo
        if(!this.isAdd){
            MerchantApi.merChangeApply.queryChangeApplyDetail(this.applyNo)
                .then(res=>{
                    if(res.success){
                        this.statusData = res.data.merStatusChangeApplyDetail
                    }
                })
        }
    },
    methods:{
        async submit(){
            console.log(this.statusData)
            if(!this.checked){
               this.Message.error('请勾选《我已阅读并同意商户变更服务协议》')
                return 
            }
           let result = await MerchantApi.merStatusChangeApplyDetail.merStatusChangeApply(this.statusData.merchantNo, this.statusData)
           if(result.success){
               this.Message.success('申请成功')
               this.$router.push({
                   name:'merchantChangeApply'
               })
           }
        },
        async getStastus(){
            if(!this.statusData.merchantNo){
                this.Message.error('请输入商户编号')
                return
            }
            let result = await MerchantApi.merStatusChangeApplyDetail.queryMerStatusChangeApply(this.statusData.merchantNo)
            result.success ? (this.statusData = result.data) : (this.Message.error(result.message))
        },
    }
}
</script>